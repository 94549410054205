import { Button } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import HandContext from "../store/hand-context";
import classes from "./BetBar.module.scss";

export default function BetBar(props) {
  const handCtx = React.useContext(HandContext);

  const MINBET = handCtx.minBet;
  const MAXBET = handCtx.maxBet;

  const [isMaxBetBtnDisabled, setIsMaxBetBtnDisabled] = useState(true);
  const [isDecreaseBetBtnDisabled, setIsDecreaseBetBtnDisabled] =
    useState(true);
  const [isIncreaseBetBtnDisabled, setIsIncreaseBetBtnDisabled] =
    useState(true);
  const [isBetOneBtnDisabled, setIsBetOneBtnDisabled] = useState(true);

  let drawBtnAction;
  let drawBtnText;
  let drawBtnDisabled = false;
  if (handCtx.isDrawBtnDisabled === true) {
    drawBtnDisabled = true;
  }

  const betTimer = useRef(null);
  const betIncrement = () => {
    betTimer.current = setInterval(
      () => handCtx.setCurrentBet((prev) => prev + 1),
      200
    );
  };

  const betDecrement = () => {
    betTimer.current = setInterval(
      () => handCtx.setCurrentBet((prev) => prev - 1),
      200
    );
  };

  function betTimeoutClear() {
    clearInterval(betTimer.current);
  }

  const betOneBtnClickHandler = () => {
    handCtx.setWinCombo("");
    handCtx.setMessage("");
    if (handCtx.balance < MINBET) {
      handCtx.setMessage("Not enough coins");
      return;
    }

    handCtx.setCurrentBet(MINBET);
  };

  const betDecreaseOneBtnClickHandler = () => {
    handCtx.setWinCombo("");
    handCtx.setMessage("");
    handCtx.setCurrentBet((prevBet) => {
      let newBet = prevBet - 1;
      if (newBet <= 0) {
        newBet = MINBET;
      }
      return newBet;
    });
  };

  const betIncreaseOneBtnClickHandler = () => {
    handCtx.setWinCombo("");
    handCtx.setMessage("");
    if (handCtx.balance < handCtx.currentBet + 1) {
      handCtx.setMessage("Not enough coins");
      return;
    }

    handCtx.setCurrentBet((prevBet) => {
      let newBet = prevBet + 1;
      if (newBet >= MAXBET) {
        newBet = MAXBET;
      }
      return newBet;
    });
  };

  const betMaxBtnClickHandler = () => {
    handCtx.setWinCombo("");
    handCtx.setMessage("");
    if (handCtx.balance < MAXBET) {
      handCtx.setMessage("Not enough coins");
      return;
    }

    handCtx.setCurrentBet(MAXBET);
  };

  if (handCtx.drawPhase === "idle") {
    drawBtnAction = handCtx.onDealButtonClick;
    drawBtnText = "Deal";
    drawBtnDisabled = false;
  } else if (handCtx.drawPhase === "deal") {
    drawBtnAction = handCtx.onDrawButtonClick;
    drawBtnText = "Draw";
    drawBtnDisabled = false;
  } else {
    drawBtnAction = () => {};
    drawBtnText = "Wait";
    drawBtnDisabled = true;
  }

  const currentBet = handCtx.currentBet;
  const drawPhase = handCtx.drawPhase;
  const balance = handCtx.balance;
  // console.log("Draw Phase", drawPhase);

  useEffect(() => {
    if (
      drawPhase === "fetching" ||
      drawPhase === "init" ||
      drawPhase === "nocoins"
    ) {
      handCtx.setIsDrawBtnDisabled(true);
    } else {
      handCtx.setIsDrawBtnDisabled(false);
    }

    if (
      drawPhase === "deal" ||
      drawPhase === "fetching" ||
      drawPhase === "init"
    ) {
      setIsBetOneBtnDisabled(true);
      setIsDecreaseBetBtnDisabled(true);
      setIsIncreaseBetBtnDisabled(true);
      setIsMaxBetBtnDisabled(true);
      return;
    }

    if (currentBet === 1) {
      setIsDecreaseBetBtnDisabled(true);
      setIsBetOneBtnDisabled(true);
    } else {
      setIsDecreaseBetBtnDisabled(false);
      setIsBetOneBtnDisabled(false);
    }

    if (currentBet === MAXBET) {
      setIsIncreaseBetBtnDisabled(true);
      setIsMaxBetBtnDisabled(true);
    } else {
      setIsIncreaseBetBtnDisabled(false);
      setIsMaxBetBtnDisabled(false);
    }
    if (balance < MAXBET) {
      setIsMaxBetBtnDisabled(true);
    }
    if (+balance === 0) {
      setIsBetOneBtnDisabled(true);
      setIsDecreaseBetBtnDisabled(true);
      setIsIncreaseBetBtnDisabled(true);
      setIsMaxBetBtnDisabled(true);
    }

    if (currentBet > balance && balance != 0 && drawPhase === "idle") {
      handCtx.setIsDrawBtnDisabled(true);
      handCtx.setMessage("Not enough coins");
      setIsIncreaseBetBtnDisabled(true);
      if (balance !== 0) {
        handCtx.setCurrentBet(balance);
      } else {
        handCtx.setCurrentBet(handCtx.minBet);
      }
    }

    if (currentBet + 1 > balance) {
      setIsIncreaseBetBtnDisabled(true);
    }
  }, [currentBet, drawPhase, balance]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     handCtx.setWinCombo("");
  //     handCtx.setMessage("");
  //   }, 1500);
  // }, [currentBet]);

  return (
    <div className={classes["bet-bar-wrapper"]}>
      <Button
        className={classes.btn}
        variant="contained"
        // href="#contained-buttons"
        size="small"
        color="secondary"
        onClick={betOneBtnClickHandler}
        disabled={isBetOneBtnDisabled}
        style={{
          margin: "5px",
        }}
      >
        Bet 1
      </Button>
      <Button
        className={classes.btn}
        variant="contained"
        // href="#contained-buttons"
        size="small"
        onClick={betDecreaseOneBtnClickHandler}
        disabled={isDecreaseBetBtnDisabled}
        onMouseLeave={betTimeoutClear}
        onMouseUp={betTimeoutClear}
        onMouseDown={betDecrement}
        style={{
          margin: "5px",
          textAlign: "center",
        }}
      >
        Bet -1
      </Button>
      <Button
        className={classes.btn}
        variant="contained"
        // href="#contained-buttons"
        // startIcon={<ArrowDropUpIcon />}
        size="small"
        onClick={betIncreaseOneBtnClickHandler}
        disabled={isIncreaseBetBtnDisabled}
        onMouseLeave={betTimeoutClear}
        onMouseUp={betTimeoutClear}
        onMouseDown={betIncrement}
        style={{
          margin: "5px",
        }}
      >
        Bet +1
      </Button>
      <Button
        className={classes.btn}
        variant="contained"
        // shape="round"
        // href="#contained-buttons"
        // startIcon={<ArrowDropUpIcon />}
        size="small"
        color="secondary"
        onClick={betMaxBtnClickHandler}
        disabled={isMaxBetBtnDisabled}
        style={{
          margin: "5px",
          // borderRadius: "14px",
        }}
      >
        Bet 100
      </Button>

      {/* <p
        className={classes.bet}
        style={{
          display: "inline-block",
          margin: "0 0 0 20px",
        }}
      >
        Your Bet: {handCtx.currentBet}
      </p> */}

      <Button
        className={classes.btn}
        variant="contained"
        // href="#contained-buttons"
        size="large"
        color="success"
        onClick={drawBtnAction}
        disabled={drawBtnDisabled}
        style={{
          margin: "5px",
        }}
      >
        {drawBtnText}
      </Button>
    </div>
  );
}
