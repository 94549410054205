import React, { useContext } from "react";
import classes from "./MessageBar.module.scss";
import HandContext from "../store/hand-context";

const MessageBar = () => {
  const handCtx = useContext(HandContext);
  const freeCoinsHidden = handCtx.isGetFreeCoinsBtnDisabled
    ? classes.hidden
    : null;

  const addFreeCoins = (
    <button
      className={`${classes["get-free-coins"]} ${freeCoinsHidden}`}
      onClick={handCtx.onFreeCoinsClick}
      title="Get Free Coins"
      disabled={handCtx.isGetFreeCoinsBtnDisabled}
    >
      Get Coins
    </button>
  );

  return (
    <>
      <div className={classes["messages-wrapper"]}>
        <p className={classes.bet}>Bet: {handCtx.currentBet}</p>
        <p className={classes["free-coins"]}>{addFreeCoins}</p>
        <p className={classes.balance}>{`Balance: ${handCtx.balance}`}</p>
      </div>
      {/* <div>{handCtx.message}</div> */}
    </>
  );
};

export default MessageBar;
