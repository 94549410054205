import React from "react";
import Cards from "./Cards";
import BetBar from "./BetBar";
import BoardTitle from "./BoardTitle";
import WinTable from "./WinTable";
import MessageBar from "./MessageBar";
import { Box, Grid } from "@mui/material";

export default function Board() {
  return (
    <Grid container spacing={0} columns={12}>
      <Grid
        item
        sx={{ textAlign: "center", width: "700px", margin: "0 auto 0 auto" }}
      >
        <BoardTitle />
      </Grid>
      <Grid item xs={12} sx={{ margin: "0 auto 0 auto" }}>
        <WinTable />
      </Grid>
      <Grid item xs={12}>
        <MessageBar />
      </Grid>
      <Grid item sx={{ margin: "0 auto 0 auto" }}>
        <Cards />
      </Grid>
      <Grid item xs={12}>
        <Box p={0}>
          <BetBar />
        </Box>
      </Grid>
    </Grid>
  );
}
