import React, { useContext } from "react";
import classes from "./BoardTitle.module.scss";
import HandContext from "../store/hand-context";

export default function BoardTitle() {
  const handCtx = useContext(HandContext);
  return (
    <>
      <div className={classes["title-wrapper"]}>
        {handCtx.message === "" && (
          <h4>
            <span className={classes["yellow-addition"]}>Free </span>
            Video Poker
            <span className={classes["yellow-addition"]}> Game</span>
            <span className={classes["beta-addition"]}> Beta</span>
          </h4>
        )}
        {handCtx.message !== "" && <h4>{handCtx.message}</h4>}
      </div>
    </>
  );
}
