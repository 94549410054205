import React, { useState, useCallback, useEffect } from "react";
import "./App.css";
import Board from "./Component/Board";
import HandContext from "./store/hand-context";
import ReactGA from "react-ga4";
import ErrorBoundary from "./Component/ErrorBoundary";
import BetBar from "./Component/BetBar";

function App() {
  const MINBET = 1;
  const MAXBET = 100;
  const [hand, setHand] = useState(Array(5).fill(""));
  const [drawPhase, setDrawPhase] = useState("init");
  const [held, setHeld] = useState([]);
  const [currentBet, setCurrentBet] = useState(1);
  const [message, setMessage] = useState("");
  const [balance, setBalance] = useState(0);
  const [winCombo, setWinCombo] = useState("");
  const [user, setUser] = useState({});
  const [isDrawBtnDisabled, setIsDrawBtnDisabled] = useState(false);
  const [isGetFreeCoinsBtnDisabled, setIsGetFreeCoinsBtnDisabled] =
    useState(true);

  ReactGA.initialize("G-QVXBTC907L");

  const rootUrl =
    // process.env.NODE_ENV === "production" ? "https://api.video-poker.win" : "";
    process.env.NODE_ENV === "production" ? "https://video-poker.win" : "";

  /**
   * Get User Data
   */
  const getUser = useCallback(async () => {
    const fetchPromise = await fetch(`${rootUrl}/api/user/getUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Basic " + btoa("Rambo" + ":" + "qwerty"),
      },
      body: JSON.stringify({}),
    });

    if (!fetchPromise.ok) {
      throw new Error("Wrong");
    }
    const userData = await fetchPromise.json();

    setUser({
      username: userData.username,
      id: userData.id,
      email: userData.email,
    });
    setBalance(userData.balance);

    if (userData.unfinishedDeal === true) {
      setMessage("Previous deal is not finished");
      setDrawPhase("deal");
      handDeal();
    } else {
      setMessage(`Hello, ${userData.username}!`);
      setDrawPhase("idle");
    }
  }, []);

  /**
   * Handle Deal
   */
  const handDeal = useCallback(async () => {
    setMessage("");
    setWinCombo("");

    const fetchPromise = await fetch(`${rootUrl}/api/deck/deal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Basic " + btoa("Rambo" + ":" + "qwerty"),
      },
      body: JSON.stringify({
        bet: currentBet,
        isMaxBet: false,
      }),
    });

    const handData = await fetchPromise.json();

    setBalance(handData.newBalance);

    setHand(handData.hand);
    if (handData.message) {
      setMessage(handData.message);
    }
  }, [currentBet]);

  /**
   * Handle Draw
   */
  const handDraw = useCallback(async () => {
    let heldSend = [];
    if (held.length === 0) {
      heldSend = [...hand];
    } else {
      for (let i = 0; i < hand.length; i++) {
        if (held.indexOf(i) === -1) {
          heldSend.push(hand[i]);
        }
      }
    }
    const fetchPromise = await fetch(`${rootUrl}/api/deck/draw`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Basic " + btoa("Rambo" + ":" + "qwerty"),
      },
      body: JSON.stringify({
        draw: heldSend,
      }),
    });

    const drawData = await fetchPromise.json();

    setHand(drawData.fullHand);
    setHeld([]);
    if (drawData.message) {
      setMessage(drawData.message);
    } else {
      setMessage("Game Over");
    }

    if (drawData.newBalance) {
      setBalance(drawData.newBalance);
    }

    if (drawData.winCombo) {
      setWinCombo(drawData.winCombo);
    } else {
      setWinCombo("");
    }
  }, [held, hand]);

  /**
   * Get Free Coins
   */
  const getFreeCoins = useCallback(async () => {
    const fetchPromise = await fetch(`${rootUrl}/api/user/getFreeCoins`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Basic " + btoa("Rambo" + ":" + "qwerty"),
      },
      body: JSON.stringify({}),
    });

    const data = await fetchPromise.json();
    if (+data.balance > 0) {
      setBalance(+data.balance);
      setIsGetFreeCoinsBtnDisabled(true);
      setMessage(`+${data.balance} coins! Good luck!`);
      setDrawPhase("idle");
      setCurrentBet(1);
    } else {
      throw new Error("No free coins this time");
    }
  }, [balance]);

  /**
   * Deal Button Handler
   */
  const onDealButtonClick = () => {
    ReactGA.event({
      category: "In-Game_Clicks",
      action: "DEAL_Clicked",
      label: "In-Game_Events",
      value: 1,
      nonInteraction: false,
      transport: "xhr",
    });

    setHand(Array(5).fill(""));
    setWinCombo("");
    setMessage("");
    setDrawPhase("fetching");
    localStorage.setItem("lastBet", currentBet);

    const cleanDraw = setTimeout(() => {
      setDrawPhase("deal");
      handDeal();
    }, 1000);
  };

  /**
   * Draw Button Handler
   */
  const onDrawButtonClick = () => {
    ReactGA.event({
      category: "In-Game_Clicks",
      action: "DRAW_Clicked",
      label: "In-Game_Events",
      value: 1,
      nonInteraction: false,
      transport: "xhr",
    });

    const aniHand = [];
    for (let i = 0; i < hand.length; i++) {
      if (held.indexOf(i) === -1) {
        aniHand[i] = "";
      } else {
        aniHand[i] = hand[i];
      }
    }
    setHand(aniHand);

    setDrawPhase("fetching");
    const cleanDraw = setTimeout(() => {
      setDrawPhase("idle");
      handDraw();
    }, 1000);
  };

  /**
   * Held Button Handler
   */
  const onHeldBtnClick = (event) => {
    const id = +event.currentTarget.value;
    setHeld((prevState) => {
      if (prevState.indexOf(id) === -1) {
        prevState.push(id);
      } else {
        prevState = prevState.filter((el) => {
          return +el !== id;
        });
      }
      return [...prevState];
    });
  };

  /**
   * Free Coins Button Handler
   */
  const onFreeCoinsClick = () => {
    setIsDrawBtnDisabled(true);
    setHand(Array(5).fill(""));
    setIsGetFreeCoinsBtnDisabled(true);
    setDrawPhase("fetching");
    setTimeout(() => {
      getFreeCoins();
      setIsDrawBtnDisabled(false);
      setDrawPhase("idle");
    }, 3000);
  };

  /**
   * onLoad effects
   */
  useEffect(() => {
    const lastBet = localStorage.getItem("lastBet");
    if (lastBet) {
      setCurrentBet(() => {
        return +lastBet;
      });
    }
    // console.log("Last Bet", lastBet);

    getUser();

    ReactGA.send({
      hitType: "pageview",
      page: "/index",
      title: "App_Loaded",
    });
  }, []);

  /**
   * Cache cards
   */
  useEffect(() => {
    const preloadDeck = {};
    const suits = ["S", "C", "D", "H"];
    const ranks = [
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "J",
      "Q",
      "K",
      "A",
    ];

    const baseUrl = "https://deckofcardsapi.com/static/img/";
    for (let s = 0; s < suits.length; s++) {
      for (let r = 0; r < ranks.length; r++) {
        const img = new Image();
        img.src = `${baseUrl}${ranks[r]}${suits[s]}.png`;
        preloadDeck[`${ranks[r]}-${suits[s]}`] = img;
      }
    }
    const backImg = new Image();
    preloadDeck.src = `${baseUrl}back.png`;
    preloadDeck["back"] = backImg;
  }, []);

  /**
   * Watch for balance - free coins flow
   */
  useEffect(() => {
    let btnDisabled = true;
    if (balance <= 0 && drawPhase === "idle") {
      setDrawPhase("nocoins");
    }

    if (balance <= 0 && drawPhase === "nocoins") {
      btnDisabled = false;
    }

    if (balance > 0 && drawPhase === "nocoins") {
      setDrawPhase("idle");
    }

    const cleanUp = setTimeout(() => {
      setIsGetFreeCoinsBtnDisabled(btnDisabled);
    }, 3000);

    return () => {
      clearTimeout(cleanUp);
    };
  }, [balance, drawPhase]);

  /**
   * Component Content
   */
  return (
    <ErrorBoundary>
      <HandContext.Provider
        value={{
          minBet: MINBET,
          maxBet: MAXBET,
          hand,
          setHand,
          onDealButtonClick,
          onHeldBtnClick,
          drawPhase,
          setDrawPhase,
          held,
          setHeld,
          onDrawButtonClick,
          currentBet,
          setCurrentBet,
          message,
          setMessage,
          balance,
          setBalance,
          winCombo,
          setWinCombo,
          user,
          setUser,
          isDrawBtnDisabled,
          setIsDrawBtnDisabled,
          onFreeCoinsClick,
          isGetFreeCoinsBtnDisabled,
          setIsGetFreeCoinsBtnDisabled,
        }}
      >
        <Board />
      </HandContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
