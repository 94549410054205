import React from "react";

const HandContext = React.createContext({
  minBet: 1,
  maxBet: 100,
  hand: [],
  setHand: () => {},
  onDealButtonClick: () => {},
  onHeldBtnClick: () => {},
  drawPhase: "idle",
  setDrawPhase: () => {},
  held: [],
  setHeld: () => {},
  onDrawButtonClick: () => {},
  currentBet: 1,
  setCurrentBet: () => {},
  message: "",
  setMessage: () => {},
  balance: 100,
  setBalance: () => {},
  winCombo: "",
  setWinCombo: () => {},
  user: {},
  setUser: () => {},
  isDrawBtnDisabled: false,
  setIsDrawBtnDisabled: () => {},
  onFreeCoinsClick: () => {},
  isGetFreeCoinsBtnDisabled: true,
  setIsGetFreeCoinsBtnDisabled: () => {},
});

export default HandContext;
