import { Button } from "@mui/material";
import "./Card.scss";

function Card(props) {
  const isHeld = props.isHeld ? "held" : "";
  let aniFlipActive = "";

  let cardName = "";
  let imgSrc = "https://deckofcardsapi.com/static/img/back.png";
  if (props.card) {
    cardName = props.card.replace(["-"], "");
    cardName = cardName.replace(["1"], "");
    imgSrc = `https://deckofcardsapi.com/static/img/${cardName}.png`;
    aniFlipActive = "flip-card-active";
  }

  return (
    <div className="cardWrapper">
      <div className={`held-indicator-wrapper ${props.isHeld ? "active" : ""}`}>
        {isHeld}
      </div>
      <div className={`flip-card ${aniFlipActive}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img
              className="cardImg"
              src="https://deckofcardsapi.com/static/img/back.png"
              alt="A Card Back"
            />
          </div>
          <div className="flip-card-back">
            <img className="cardImg" src={imgSrc} alt="A Card" />
          </div>
        </div>
      </div>
      <div className="heldBtnWrapper">
        <Button
          onClick={props.onHeldBtnClickHandler}
          // id={props.cardID}
          value={props.cardID}
          // className={heldBtnMutedClass}
          variant="contained"
          color="error"
          style={{}}
          // value={index}
          disabled={props.isHeldBtnDisabled}
        >
          held
        </Button>
      </div>
    </div>
  );
}

export default Card;
